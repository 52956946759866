import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Typography, Button } from "antd";
import PrimaryCta from "../Buttons/primary-cta";
import checkmark from "../../images/svg/checkmark.svg";
import playIcon from "../../images/svg/play.svg";
import chevron from "../../images/svg/chevron_left.svg";
import NoVisualsStyles from "./NoVisualsStyles";

const { Title } = Typography;

const NoVisualsCustom = (props) => {
  const {
    className,
    sectionData,
    simple,
    complex,
    actionSetter,
    leftCta,
    openInNewTab,
  } = props;

  return (
    <div
      className={`${className} no-visuals-text ${
        complex && "no-visuals-text__complex"
      }`}
      style={{
        paddingBottom: `${!sectionData.PrimaryCta && "64px !important"}`,
      }}
    >
      {simple && (
        <Row justify="center" align="middle">
          <Col span={24}>
            <Title level={2} className="text-center">
              {sectionData.title}
              <span className="blue_dot">.</span>{" "}
            </Title>
            <p className="text-center">{sectionData.subtext}</p>
            {sectionData.PrimaryCta && (
              <PrimaryCta
                ctaTitle={sectionData.PrimaryCta.ctaTitle}
                target={sectionData.PrimaryCta.ctaTarget}
                className="custom-center z-[7]"
                openInNewTab={openInNewTab}
              />
            )}
            {actionSetter && (
              <Button
                className="cta-primary custom-center"
                type="primary"
                onClick={actionSetter}
              >
                Current openings
                <img
                  src={chevron}
                  style={{ margin: "5px 5px 2px 10px" }}
                  alt="chevron icon"
                />
              </Button>
            )}
          </Col>
        </Row>
      )}
      {complex && (
        <Row justify="center" gutter={[64, 24]}>
          <Col lg={12} xs={24}>
            <Title level={2}>
              {sectionData.title}
              <span className="blue_dot">.</span>{" "}
            </Title>
            <p>{sectionData.subtext}</p>
            {sectionData.PrimaryCta && leftCta && (
              <PrimaryCta
                className="mt-10"
                ctaTitle={sectionData.PrimaryCta.ctaTitle}
                target={sectionData.PrimaryCta.ctaTarget}
                openInNewTab={openInNewTab}
              />
            )}
          </Col>
          <Col lg={12} xs={24}>
            <div className="mt-10 lg:mt-0">
              {sectionData.list &&
                sectionData.list.map((item, idx) => (
                  <span className="no-visuals-text__list" key={idx.toString()}>
                    <img src={checkmark} alt="" />
                    <p dangerouslySetInnerHTML={{ __html: item }} />
                  </span>
                ))}
              {sectionData.PrimaryCta && !leftCta && (
                <PrimaryCta
                  ctaTitle={sectionData.PrimaryCta.ctaTitle}
                  target={sectionData.PrimaryCta.ctaTarget}
                  openInNewTab={openInNewTab}
                />
              )}
            </div>
          </Col>
        </Row>
      )}

      <NoVisualsStyles />
    </div>
  );
};

NoVisualsCustom.propTypes = {
  className: PropTypes.string,
  sectionData: PropTypes.objectOf(PropTypes.any),
  simple: PropTypes.bool,
  complex: PropTypes.bool,
  leftCta: PropTypes.bool,
  openInNewTab: PropTypes.bool,
};
NoVisualsCustom.defaultProps = {
  className: "",
  sectionData: "",
  simple: false,
  complex: false,
  leftCta: false,
  openInNewTab: false,
};

export default NoVisualsCustom;
