import React from "react";
import { viewports } from "../../style-vars";

const { smOrSmaller, mdOnly } = viewports;

const NoVisualsStyles = () => (
  <style jsx global>{`
    .no-visuals {
      position: relative;
      padding: 40px 20%;
    }
    .no-visuals .cta-secondary {
      display: flex;
      margin: 32px auto;
    }
    @media ${mdOnly}
      .no-visuals {
        padding: 40px 100px;
      }
    }
    @media ${smOrSmaller} {
      .no-visuals {
        padding: 40px 16px;
      }
    }
    @media ${smOrSmaller} {
      .no-visuals h2,
      .no-visuals p {
        text-align: left !important;
      }
    }
    .no-visuals h2 {
      font-weight: 800 !important;
      margin-bottom: 16px;
    }
    .no-visuals p {
      color: #596779;
      font-size: 16px;
      line-height: 1.7;
    }
    .no-visuals-text {
      background: #f3f4f6;
      margin: 40px auto;
      max-width: 95%;
      padding: 64px 100px;
      border-radius: 24px;
      width: 95%;
    }
    @media ${smOrSmaller} {
      .no-visuals-text {
        padding: 20px;
        margin-bottom: 128px;
      }
    }
    .no-visuals-text__complex {
      padding: 64px 100px !important;
    }
    .no-visuals-text h2.ant-typography {
      margin-top: -7px;
    }
    @media ${smOrSmaller} {
      .no-visuals-text__complex {
        padding: 20px !important;
        margin-bottom: 128px !important;
      }
    }
    .no-visuals-text .custom-center {
      position: relative;
      left: 50%;
      top: 120%;
      transform: translateX(-50%);
      margin-top: 20px;
    }
    .no-visuals-text__list {
      display: grid;
      grid-template-columns: 24px 1fr;
      grid-gap: 12px;
      margin-bottom: 12px;
    }
    
    @media ${viewports.lgOrBigger} {
      .no-visuals h2.ant-typography {
        max-width: 500px;
        margin: 0 auto;
      }
      .no-visuals p.text-center {
        max-width: 600px;
        margin: 0 auto;
      }
    }
  `}</style>
);

export default NoVisualsStyles;
